import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Form from './pages/Form';
import Main from './pages/Main';
const App = () => {
  return (
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
   </BrowserRouter>
  )
}

export default App