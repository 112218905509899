import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineWbSunny } from "react-icons/md";
import { FaCloudRain } from "react-icons/fa6";
import '../styles/hover.css';
import { gql, useQuery } from '@apollo/client';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Grid } from 'react-loader-spinner'
import { DateTime, Settings } from 'luxon';
import Modal from '../components/Modal';
import Form from './Form';
import html2canvas from 'html2canvas'
Settings.defaultLocale = 'ko';




const GET_REVENUE_WEEK_PER_DATA = gql`
query getRevenueWeekPercentData($input: String!,$input2:String!) {
  getRevenueWeekPercentData(reservationDate: $input,reservationDateTime:$input2) {
    reservationDate,
    reservationDateTime,
    today,
    oneDaysAgo,
    twoDaysAgo,
    threeDaysAgo,
    fourDaysAgo,
    fiveDaysAgo,
    sixDaysAgo,
    sevenDaysAgo
  }
}
`;


const Main = () => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    scales: {
      y: {
        ticks: {
          // 축 레이블 포맷을 사용자 정의하는 callback 함수
          callback: function (value: any, index: any, values: any) {
            return `${value}%`; // 각 레이블 값 뒤에 '°'를 추가
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true, // 범례 표시 설정
      },
      tooltip: {
        callbacks: {
          // 툴팁 내용을 사용자 정의하는 callback 함수
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + '%'; // 툴팁 값 뒤에 '°'를 추가
            }
            return label;
          }
        }
      }
    }
  };

  const now = DateTime.now();
  const variantDate = now.toFormat('yyyy-MM-dd')
  const yesterDate = now.minus({ day: 1 }).toFormat('yyyy-MM-dd')
  const twoDaysAgoDate = now.minus({ day: 2 }).toFormat('yyyy-MM-dd')
  const threeDaysAgoDate = now.minus({ day: 3 }).toFormat('yyyy-MM-dd')
  const fourDaysAgoDate = now.minus({ day: 4 }).toFormat('yyyy-MM-dd')
  const fiveDaysAgoDate = now.minus({ day: 5 }).toFormat('yyyy-MM-dd')
  const sixDaysAgoDate = now.minus({ day: 6 }).toFormat('yyyy-MM-dd')
  const sevenDaysAgoDate = now.minus({ day: 7 }).toFormat('yyyy-MM-dd')

  const minusDate = (plus: number) => {
    return now.minus({ day: plus }).toFormat('MM.dd')
  }

  const stringAMDate = variantDate
  const stringPMDate = variantDate

  const { data: amData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${stringAMDate}`, input2: `9` },
  });
  const { data: pmData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${stringPMDate}`, input2: `17` },
  });

  const { data: yaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${yesterDate}`, input2: `9` },
  });
  const { data: ypData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${yesterDate}`, input2: `17` },
  });

  const { data: twoaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${twoDaysAgoDate}`, input2: `9` },
  });
  const { data: twopData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${twoDaysAgoDate}`, input2: `17` },
  });

  const { data: threeaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${threeDaysAgoDate}`, input2: `9` },
  });
  const { data: threepData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${threeDaysAgoDate}`, input2: `17` },
  });

  const { data: fouraData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${fourDaysAgoDate}`, input2: `9` },
  });
  const { data: fourpData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${fourDaysAgoDate}`, input2: `17` },
  });

  const { data: fiveaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${fiveDaysAgoDate}`, input2: `9` },
  });
  const { data: fivepData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${fiveDaysAgoDate}`, input2: `17` },
  });

  const { data: sixaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${sixDaysAgoDate}`, input2: `9` },
  });
  const { data: sixpData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${sixDaysAgoDate}`, input2: `17` },
  });

  const { data: sevenaData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${sevenDaysAgoDate}`, input2: `9` },
  });
  const { data: senvenpData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${sevenDaysAgoDate}`, input2: `17` },
  });

  const { data: amAvgData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${variantDate}`, input2: `9` },
  });
  const { data: pmAvgData } = useQuery(GET_REVENUE_WEEK_PER_DATA, {
    variables: { input: `${variantDate}`, input2: `17` },
  });




  const amTempData = [`${amData?.getRevenueWeekPercentData?.today ?? '-'}`, `${yaData?.getRevenueWeekPercentData?.today ?? '-'}`, `${twoaData?.getRevenueWeekPercentData?.today ?? '-'}`, `${threeaData?.getRevenueWeekPercentData?.today ?? '-'}`, `${fouraData?.getRevenueWeekPercentData?.today ?? '-'}`, `${fiveaData?.getRevenueWeekPercentData?.today ?? '-'}`, `${sixaData?.getRevenueWeekPercentData?.today ?? '-'}`, `${sevenaData?.getRevenueWeekPercentData?.today ?? '-'}`]
  const pmTempData = [`${pmData?.getRevenueWeekPercentData?.today ?? '-'}`, `${ypData?.getRevenueWeekPercentData?.today ?? '-'}`, `${twopData?.getRevenueWeekPercentData?.today ?? '-'}`, `${threepData?.getRevenueWeekPercentData?.today ?? '-'}`, `${fourpData?.getRevenueWeekPercentData?.today ?? '-'}`, `${fivepData?.getRevenueWeekPercentData?.today ?? '-'}`, `${sixpData?.getRevenueWeekPercentData?.today ?? '-'}`, `${senvenpData?.getRevenueWeekPercentData?.today ?? '-'}`]
  const amPerData = [`${amAvgData?.getRevenueWeekPercentData?.oneDaysAgo ?? '-'}`, `${amAvgData?.getRevenueWeekPercentData?.sevenDaysAgo ?? '-'}`]
  const pmPerData = [`${pmAvgData?.getRevenueWeekPercentData?.oneDaysAgo ?? '-'}`, `${pmAvgData?.getRevenueWeekPercentData?.sevenDaysAgo ?? '-'}`]
  const amTemps = [...amTempData].reverse().map(item => parseFloat(item));
  const pmTemps = [...pmTempData].reverse().map(item => parseFloat(item));

  const tableAMData =
  {
    name: '오전',
    weather: <MdOutlineWbSunny size={30} className='flex text-red-300' />,
    data: amTempData.reverse()
  }



  const tablePMData =
  {
    name: '오후',
    weather: <MdOutlineWbSunny size={30} className='flex text-red-300' />,
    data: pmTempData.reverse()
  }


  const days: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined)[] = []
  for (let i = 7; i >= 0; i -= 1) {
    days.push(minusDate(i))
  }
  const labels = days;

  const lineData = {
    labels,
    datasets: [
      {
        label: '오전',
        data: amTemps.map((i) => i),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '오후',
        data: pmTemps.map((i) => i),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };


  const TableComponent = ({ amTableData, pmTableData }: any) => {
    const daysWithWeekdays = ['일자']; // 첫 번째 항목은 시간대를 위한 공간입니다.

    for (let i = 7; i >= 0; i -= 1) {
      const date = DateTime.now().minus({ days: i });
      // 'M/d'는 월/일 형식을 의미하고, 'EEE'는 요일을 한글로 가져옵니다 (예: 월, 화).
      const formattedDate = `${date.toFormat('M/d')}(${date.toFormat('ccc')})`;
      daysWithWeekdays.push(formattedDate);
    }

    const renderDataRow = (dataRow: any, idx: string) => (
      <tr key={idx}>
        <td className="text-center p-2 border border-gray-300 font-bold">{dataRow.name}</td>
        {dataRow.data.map((num: any, idx: number) => (
          <td key={idx} className="text-center p-2 border border-gray-300">{num.includes('-') ? num : '+' + num}%</td>
        ))}
      </tr>
    );

    const mobileRow = (amDataRow: any, pmDataRow: any) => {
      // map 함수를 사용하여 JSX 배열 반환
      return amDataRow.data.map((i: any, index: number) => (
        <div key={index} className='flex flex-wrap w-full justify-around items-center border border-gray-300 py-[8px] px-[4px]'> {/* 키 추가 필요 및 요소 사이 간격 추가 */}
          <div className='flex  text-[14px] justify-center items-center w-[26%]  '>
            {days[index]}
          </div>
          <div className='flex text-[14px] justify-center items-center w-[12%] bg-red-200'> {/* 흐린 빨강색 배경 추가 */}
            오전
          </div>
          <div className='flex justify-center items-center w-[25%] '>
            {i.includes('-') ? i : '+' + i}%
          </div>
          <div className='flex text-[14px] justify-center items-center w-[12%] bg-blue-200'> {/* 흐린 파랑색 배경 추가 */}
            오후
          </div>
          <div className='flex justify-center items-center w-[25%]'>
            {pmDataRow.data[index].includes('-') ? pmDataRow.data[index] : '+' + pmDataRow.data[index]}%
          </div>
        </div>
      ));
    }
    return (
      <>
        <div className="overflow-x-auto w-full sm:hidden">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                {daysWithWeekdays.map((dateString, index) => (
                  <th key={index} className="bg-gray-100 p-2 text-center font-bold border border-gray-300">{dateString}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {renderDataRow(amTableData, 'am')}
              {renderDataRow(pmTableData, 'pm')}
            </tbody>
          </table>
        </div>

        <div className='lg:hidden px-[16px] w-full flex flex-col'>
          <div className='flex flex-col'>
            {mobileRow(amTableData, pmTableData)}
          </div>
        </div>

      </>
    );
  };


  const [color, setColor] = useState(false)
  const [title, setTitle] = useState('9시')
  const [onePer, setOnePer] = useState('')
  const [sevenPer, setSevenPer] = useState('')
  const [disLoading, setDisLoading] = useState(true);
  useEffect(() => {
    // 데이터가 모두 로딩되었을 때 로직 실행
    const hours = new Date().getHours();
    const dataToDisplay = hours >= 9 && hours < 17 ? amData : pmData;
    const dataYeDisplay = hours >= 9 && hours < 17 ? yaData : ypData;
    setOnePer(hours >= 9 && hours < 17 ? amPerData[0] : pmPerData[0]);
    setSevenPer(hours >= 9 && hours < 17 ? amPerData[1] : pmPerData[1]);
    setTitle(hours >= 9 && hours < 17 ? '오전 9시' : '오후 5시');
    const diff = dataToDisplay?.getRevenueWeekPercentData?.today - dataYeDisplay?.getRevenueWeekPercentData?.today;

    setColor(diff > 0);
    setDisLoading(false); // 데이터 로딩이 완료되었음을 설정


  }, [amData, pmData, yaData, ypData, color])



  const DisplayDifferentDataComponent = () => {
    // 현재 시간을 가져옵니다.
    const hours = new Date().getHours();

    // 오전 5시 이후이고 오후 5시 이전이면 amData를, 그렇지 않으면 pmData를 사용합니다.å
    const dataToDisplay = hours >= 9 && hours < 17 ? amData : pmData;
    const dataYeDisplay = hours >= 9 && hours < 17 ? yaData : ypData;

    const diff = dataToDisplay && `${dataToDisplay?.getRevenueWeekData?.today - dataYeDisplay?.getRevenueWeekData?.today}°`

    return (
      <>
        {(diff && (isNaN(diff) ?? '-'))}
      </>
    );
  };

  const [isVisible, setIsVisible] = useState(false);

  const onVisible = () => {
    setIsVisible(!isVisible)
  }

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const captureRef = useRef<any>(null);
  const today = new Date();
  const month = today.getMonth() + 1; // JavaScript에서 월은 0부터 시작하므로 1을 더해줍니다.
  const day = today.getDate();


  const downloadImage = async () => {
    if (captureRef.current) { // `captureRef.current`가 `null`이 아닌지 확인합니다.
      const canvas = await html2canvas(captureRef.current);
      const image = canvas.toDataURL("image/png", 1.0);
      const link = document.createElement('a');
      link.download = 'screenshot.png';
      link.href = image;
      document.body.appendChild(link); // DOM에 링크를 추가합니다 (Firefox에서 필요할 수 있음)
      link.click();
      document.body.removeChild(link); // 사용 후 링크를 제거합니다.
    }
  };


  return (
    <div className={`sm:overflow-x-hidden sm:px-[8px] sm:mb-[130px] sm:py-0 sm:pb-[8px] sm:mx-auto relative flex flex-col sm:z-40 w-full justify-center items-center px-[64px] py-[60px] bg-[#FDFDFF]`}>
      <div className={`lg:hidden fixed z-50 rounded-md bg-white w-full py-[16px] flex flex-col justify-center items-center border-[1px] shadow-2xl bottom-0 ${isVisible ? 'sm:hidden' : 'sm:visible'}`}>
        <button className='mb-[20px] border-[1px] rounded-lg px-[16px] border-[#333]' onClick={downloadImage}>
          예보 이미지 다운로드
        </button>
        <button className='sm:px-[32px] rounded-md py-[4px] px-[8px] border border-gray-100 bg-yellow-300 text-black' onClick={onVisible}>
          알림톡으로 예보 받기(무료)
        </button>
      </div>


      <div className='sm:flex sm:flex-col sm:relative sm:z-30 fixed top-0  z-50 flex w-full py-[8px] justify-around items-center bg-[#FDFDFF] border-b-[1px] border-gray-200  font-bold '>
        <span className='sm:flex font-bold text-3xl'>
          오늘의 숙박 예보
        </span>
        <div className='sm:flex-wrap sm:gap-[4px] sm:pt-[16px] flex items-center gap-[16px]'>
          <button className='sm:px-[32px] rounded-md py-[4px] px-[8px] border border-gray-100 bg-yellow-300 text-black' onClick={onVisible}>
            알림톡으로 예보 받기(무료)
          </button>
        </div>
      </div>

      <div className='sm:w-full flex flex-col w-[80%] py-[16px] gap-[16px] justify-center items-center rounded-md border border-gray-300 shadow-sm bg-white'>
        <span className='sm:text-[24px] font-semibold text-[36px] text-[#111111]'>
          오늘의 숙박예보 소개
        </span>
        <span className='sm:text-[18px] font-semibold text-[24px] text-[#808080]'>
          오늘이 장사가 잘되는 날인지 궁금하지 않으신가요?
        </span>
        <span className='sm:text-[18px] font-semibold text-[24px] text-[#808080]'>
          오늘의 숙박 예보가 전국의 숙박업 매출 동향을 한눈에 보여드립니다
        </span>

        <span className='sm:text-[18px] font-semibold text-[24px] text-[#808080]'>
          숙박업 매출 동향을 파악하고 객실 가격을 최적화해보세요
        </span>


      </div>

      <div className=' my-[60px] w-full bg-gray-200 h-[2px]'></div>



      <div className='w-full flex flex-col justify-center items-center' ref={captureRef}>
        <img src='images/venditLogo.svg' alt='logo' className='lg:hidden mb-[20px]' />
        <span className='mb-[20px] lg:hidden'>접속 주소 https://www.weather.vendit.co.kr</span>
        <div className='sm:w-full flex flex-col w-[80%] py-[16px] gap-[8px] justify-center items-center rounded-md border border-gray-300 shadow-sm bg-white'>

          {
            disLoading ? <Grid
              visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            /> :
              <>
                <span className='sm:text-[24px] font-semibold text-[24px] text-[#111111]'>
                  {month}월 {day}일 예약 예보
                </span>
                <span className='sm:text-[18px] font-semibold text-[24px] text-[#888]'>
                  어제 대비 오늘 전국 매출 동향입니다
                </span>
                <span className='font-bold text-4xl mb-[32px]'>
                  {title}
                </span>
                <div className='flex flex-col  items-center justify-center gap-2'>
                  {color ? <MdOutlineWbSunny size={100} className='flex text-red-300' /> : <FaCloudRain size={100} className='flex text-blue-300' />}
                  <span className='font-bold text-4xl my-[4px] sm:text-4xl'>
                    {color ? '맑음' : '흐림'}
                  </span>
                </div>
                <span className='sm:text-[24px] font-semibold text-black  text-2xl'>
                  {'어제 동일 시간보다 '} <span className={`${color ? ' text-red-700' : 'text-blue-700'}`}><DisplayDifferentDataComponent />{(color ? '↑' : '↓')}</span>
                </span>
                <span className='sm:text-[24px] font-semibold text-black  text-2xl'>
                  {'오늘 평균 예약률이'} <span className={`${color ? ' text-red-700' : 'text-blue-700'}`}>{(color ? '높습니다' : '낮습니다')}</span>
                </span>
                <span className='sm:text-[24px] font-semibold text-black  text-2xl'>
                  {`전일 대비 ${onePer}%`}
                </span>
                <span className='sm:text-[24px] font-semibold text-black  text-2xl'>
                  {`전주 대비 ${sevenPer}%`}
                </span>
                <span className='sm:text-[12px] font-semibold text-[#808080]  text-lg'>
                  {(color ? '객실 가격을 올려보는 것은 어떨까요?' : '객실 가격을 내려보는 것은 어떨까요?')}
                </span>
              </>
          }

        </div>

        <div className=' my-[60px] w-full bg-gray-200 h-[2px]'></div>

        <div className='sm:w-full sm:px-0 flex flex-col w-[80%] px-[32px] py-[32px] gap-[8px] justify-center items-center rounded-md border border-gray-300 shadow-sm'>
          <span className='font-bold text-3xl'>
            주간 예약 예보
          </span>
          <span className='sm:text-[16px] font-bold text-xl text-[#888]'>
            한주간의 매출 동향을 그래프로 보여드립니다
          </span>
          <span className='sm:text-[12px] font-bold text-xl text-[#888]'>
            tip1 오전 매출 좋으면 오후 매출도 좋을 확률이 높습니다
          </span>
          <span className='sm:text-[12px] font-bold text-xl text-[#888]'>
            tip2 전국의 매출 흐름이 상승세라면 객실 가격을 올리고
          </span>
          <span className='sm:text-[12px] font-bold text-xl text-[#888]'>
            하락세라면 객실 가격을 낮춰보세요
          </span>
          {<Line options={options} data={lineData} /> ?? ''}
          <TableComponent amTableData={tableAMData} pmTableData={tablePMData} />


        </div>
      </div>

      <button className='sm:hidden mt-[20px] border-[1px] rounded-lg px-[16px] border-[#333]' onClick={downloadImage}>
        예보 이미지 다운로드
      </button>


      <div className=' my-[60px] w-full bg-gray-200 h-[2px]'></div>


      <div className='sm:w-full flex flex-col w-[80%] py-[32px] gap-[16px] justify-center items-center rounded-md border border-gray-300 shadow-sm'>
        <span className='sm:text-[24px] font-semibold text-[36px] text-[#111111]'>
          어떻게 예측하나요?
        </span>
        <span className='sm:text-[18px] font-semibold text-[18px] text-[#808080]'>
          벤디트 플랫폼에 누적된 빅데이터를 활용하여 매일 예약률을 예측하는 서비스입니다.
        </span>

        <div className='sm:w-full sm:flex-col flex flex-wrap justify-center items-center gap-[32px] sm:px-[4px] sm:gap-0'>
          <img src='images/kakao2.png' alt='kakao' className='flex h-[400px] w-auto sm:w-full rounded-md object-cover sm:object-contain sm:h-auto' />
          <img src='images/kakao.png' alt='kakao' className='flex h-[400px] w-auto sm:w-full rounded-md object-cover sm:object-contain sm:h-auot' />
        </div>

        <button className=' sm:px-[32px] rounded-md py-[8px] px-[128px] border border-gray-100 bg-yellow-300 text-black' onClick={onVisible}>
          알림톡으로 예보 받기(무료)
        </button>

      </div>

      <div className='sm:w-full w-[80%] bg-gray-200 h-[2px] my-[30px]'></div>

      <div className='flex flex-wrap w-full justify-center items-center gap-[32px] drop-shadow-md'>
        <div className='flex flex-col'>
          <div className='flex'>
            <span className='pr-[16px] font-medium text-gray-500'>
              연락처
            </span>
            <span className=' font-semibold  '>
              1644-5442
            </span>
          </div>
          <div className='flex sm:flex-col '>
            <span className='pr-[16px] font-medium text-gray-500'>
              주소
            </span>
            <span className=' font-semibold'>
              서울시 구로구 디지털로 272, 한신IT타워 401호
            </span>
          </div>
          <div className='flex'>
            <span className='pr-[16px] font-medium text-gray-500' >
              이메일
            </span>
            <span className=' font-semibold  '>
              business@vendit.co.kr
            </span>
          </div>
          <div className='flex'>
            <span className='pr-[16px] font-medium text-gray-500'>
              사업자 등록번호
            </span>
            <span className=' font-semibold  '>
              865-87-02013
            </span>
          </div>
        </div>


      </div>
      <div className='sm:w-full w-[80%] bg-gray-200 h-[2px] my-[30px]'></div>
      <span className='text-gray-500'>
        © Copyright 2022 Vendit.Inc
      </span>

      <Modal isVisible={isVisible} onClose={handleCloseModal}>
        <Form onClose={handleCloseModal} />
      </Modal>
    </div>
  )
}
export default Main