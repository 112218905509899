import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.css';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const client = new ApolloClient({
  uri: `${backendUrl}/graphql`, // GraphQL 서버의 엔드포인트로 교체해야 합니다.
  cache: new InMemoryCache(),
});


root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>

  </React.StrictMode>
);

