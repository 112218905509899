import React from 'react';

const Modal = ({ isVisible, onClose, children }: any) => {
  if (!isVisible) return null;

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"  onClick={handleModalClick}>
      <div className="bg-white rounded-lg p-[32px]">{children}</div>
    </div>
  );
};

export default Modal;