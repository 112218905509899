import React, { useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client';
const ADD_PHONE_NUMBER = gql`
  mutation addPhoneNumber($number: String!, $userName: String!) {
    addPhoneNumber(number: $number, userName: $userName )
  }
`;

const Form = ({ onClose }: any) => {

  const [navPhoneNumber, setNavPhoneNumber] = useState('');
  const [name, setName] = useState('');

  const [addPhoneNumber, { data, loading, error }] = useMutation(ADD_PHONE_NUMBER, {
    onCompleted: (data) => {
      console.log(data)
      // "addPhoneNumber" 필드가 null이고, error 객체가 존재하는 경우
      if (data.addPhoneNumber === "Phone number added successfully.") {
        alert("등록되었습니다.");
      }
    },
    onError: (error) => {
      // 네트워크 오류나 쿼리 오류 등의 처리
      console.error("Mutation error:", error);
      alert("이미 등록된 번호입니다.");
    }
  });


  const navHandleChange = (event: any) => {
    const formattedInput = formatPhoneNumber(event.target.value);
    setNavPhoneNumber(formattedInput);
  };

  const nameChange = (event: any) => {
    setName(event.target.value);
  }

  const navHandleSubmit = () => {
    if (navPhoneNumber.length === 13 && navPhoneNumber.startsWith('010') && name.length !== 0) {
      addPhoneNumber({ variables: { number: navPhoneNumber, userName: name } });
      setNavPhoneNumber('')
      setName('')
      onClose()
    } else {
      alert('핸도폰 번호 양식 또는 업장명(이름)을 입력해 주세요.')
    }
  };

  const formatPhoneNumber = (value: string) => {
    // 숫자만 추출
    const numbers = value.replace(/\D/g, '');

    // 숫자 부분을 그룹으로 나누어 하이픈 추가하기 위한 인덱스 시그니처가 있는 객체
    const char: { [key: number]: string } = { 3: '-', 7: '-' };
    const phoneNumber = numbers.substring(0, 11).split('').map((num, idx) => {
      return char[idx] ? char[idx] + num : num;
    }).join('');

    // 하이픈이 추가된 문자열 반환
    return phoneNumber;
  }



  return (
    <div className='flex flex-col w-full h-full items-center justify-center my-auto z-50' tabIndex={-1} >
      <picture className='flex justify-center items-center w-[50%]'>
        <img src="images/venditLogo.svg" alt='logo' />
      </picture>

      <div className="flex flex-col w-full mt-[32px] gap-[16px] px-[16px] w-fulljustify-center items-center ">
        <input
          type="text"
          id="email"
          name="email"
          className="p-2 border border-gray-300 rounded-md w-full"
          placeholder="업장명 또는 이름"
          value={name}
          onChange={nameChange}
        />
        <input
          id="phone"
          name="phone"
          className="p-2 border border-gray-300 rounded-md w-full"
          placeholder="핸드폰 번호"
          value={navPhoneNumber}
          onChange={navHandleChange}
        />
        <span className=' font-bold text-[14px] text-gray-700'>
          당사는 매출 알림 예보 전송 및 운영, 신규 서비스, 각종 이벤트 및 프로모션 등 홍보 및 관련 정보 전송 목적을 위하여<br /> 당사의 서비스 종료 시까지 귀하의 전화번호, 상호명을 수집하여 이용합니다. 이에 동의 하십니까?
        </span>
        <button type="submit" className="w-full border border-gray-100 bg-yellow-300 text-black font-bold py-2 px-4 rounded" onClick={navHandleSubmit}>
          동의하고 알람 받기
        </button>
      </div>
    </div>
  )
}

export default Form